import React,{ useState, useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { FaRegTrashAlt } from "react-icons/fa";
import { FormFileInput, FormButton } from '../index';
import { createCv, updateCv, deleteCv } from '../../../actions/cv';
import { CV_MESSAGE } from '../../../constants/actionTypes';

const Cv = (props) => {
     const dispatch = useDispatch();

     const handleDelBtn = (e) => {
          e.preventDefault();
          dispatch(deleteCv(props.cvId));
     };

     return (
          <React.Fragment>
               <p className='dashboard__file-title' onClick={ props.func }>Current CV File : { props.title }</p>
               <button className='dashboard__file-delete' onClick={ handleDelBtn }><FaRegTrashAlt/></button>
          </React.Fragment>
     )
}

function CvFileForm() {
     const dispatch = useDispatch();
     const cv = useSelector(state => state.cv);
     const [ id ] = cv.data.map(i => i._id);
     const [ file, setFile ] = useState({ title: '', base64: '', size: '' });
     const [ message, setMessage ] = useState('Save');

     useEffect(() => {
          cv.message ? setMessage(cv.message) : setMessage('Save');
          if(cv.message === 'CV added' || cv.message === 'CV updated'){
               clear();
          }
          setTimeout(() => {
               dispatch({ type: CV_MESSAGE, payload: null })
          }, 3000)
     }, [dispatch, cv.message, message])


     const handleCvFile = (e) => {
          e.preventDefault();
          const data = e.target.files[0];
          const reader = new FileReader();
          reader.readAsDataURL(data);
          
          reader.onload = () => {
               setFile({ ...file, title: data.name, base64: reader.result, size: data.size });
          };
     };

     const handleSubmit = (e) => {
          e.preventDefault();
               
          if (cv.data.length === 0) {
               dispatch(createCv(file));
          } else {
               dispatch(updateCv(id, file));
          };
     };

     const clear = () => {
          setFile({ title: '', base64: '', sise: '' });
     };
     
     return (
          <form autoComplete='off' noValidate className='dashboard__form' onSubmit={ handleSubmit }>
               <div className="dashboard__form-col">
                    <FormFileInput
                         formName='pdf'
                         formId='pdf'
                         formFileTitle={ file.title }
                         formFileSize={ file.size }
                         formFunc={ handleCvFile }
                    />
                    <div className='dashboard__file-result'>
                         { cv.data.map(item =>  
                              <Cv 
                                   key={ item._id } 
                                   cvId={ item._id } 
                                   title={ item.title }
                                   func={() => setFile({ ...item })}
                              /> 
                         )}
                    </div>
               </div>
               <div className="dashboard__form-col">
                    <FormButton text={ message } message={ cv.message }/>
               </div>
          </form>
     )
}

export default CvFileForm;
