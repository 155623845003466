import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPost, deletePost, updatePost } from "../../../actions/blog";
import {
	FormInput,
	FormButton,
	Post,
	QuillTextArea,
	Pagination,
} from "../../mainComponents/index";
import { POSTS_MESSAGE } from "../../../constants/actionTypes";

function BlogForm() {
	const dispatch = useDispatch();
	const blog = useSelector((state) => state.blog);
	const [data, setData] = useState({
		title: "",
		subtitle: "",
		body: "",
		category: "",
		tags: [],
		userId: "",
	});
	const [message, setMessage] = useState("Save");
	// Variable to past to quil
	const [oldPostBody, setOldPostBody] = useState("");
	// Pagination variables
	const [currentPage, setCurrentPage] = useState(1);
	const [posts, setPosts] = useState([]);
	const postsPerPage = 5;
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

	useEffect(() => {
		if (blog) setPosts(blog.data);
	}, [blog]);

	useEffect(() => {
		blog.message ? setMessage(blog.message) : setMessage("Save");
		if (
			blog.message === "Post updated" ||
			blog.message === "Post added"
		) {
			clear();
			// the line below cleans editor textarea
			setOldPostBody(" ");
		}
		setTimeout(() => {
			dispatch({ type: POSTS_MESSAGE, payload: null });
		}, 3000);
	}, [dispatch, blog.message, message]);

	const handleInputs = (e) => {
		e.preventDefault();
		if (e.target.name === "blogTags") {
			const rawTags = e.target.value.split(",");
			const tags = rawTags.map((item) => item.trim());
			setData({ ...data, tags });
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (data._id === undefined) {
			dispatch(createPost(data));
		} else {
			dispatch(updatePost(data._id, data));
		}
	};

	const clear = () => {
		setData({
			title: "",
			subtitle: "",
			body: "",
			category: "",
			tags: [],
			userId: "",
		});
	};

	return (
		<form
			autoComplete="off"
			noValidate
			className="dashboard__form"
			onSubmit={handleSubmit}
		>
			<FormInput
				formType="text"
				formName="title"
				formValue={data.title}
				formFunc={handleInputs}
				formPlaceholder="Title"
			/>
			<FormInput
				formType="text"
				formName="subtitle"
				formValue={data.subtitle}
				formFunc={handleInputs}
				formPlaceholder="Subtitle"
			/>
			<FormInput
				formType="text"
				formName="category"
				formValue={data.category}
				formFunc={handleInputs}
				formPlaceholder="Category"
			/>
			<FormInput
				formType="text"
				formName="blogTags"
				formValue={data.tags}
				formFunc={handleInputs}
				formPlaceholder="Technology Tags"
			/>
			<QuillTextArea
				handleQuillBody={setData}
				quillReplaceBody={oldPostBody}
			/>
			<FormButton text={message} message={blog.message} />
			<div className="dashboard__form-col">
				{currentPosts &&
					currentPosts
						.map((post) => (
							<Post
								key={post._id}
								postTitle={post.title}
								// postDate={post.createdAt}
								postFunc={() => {
									setData(post);
									setOldPostBody(post.body);
								}}
								postBtnFunc={(e) => {
									e.preventDefault();
									dispatch(deletePost(post._id));
								}}
							/>
						))
						.reverse()}
			</div>
			{posts.length !== 0 && (
				<Pagination
					totalPosts={posts.length}
					postsPerPage={postsPerPage}
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
				/>
			)}
		</form>
	);
}

export default BlogForm;
