import React from 'react';
import { Link } from 'react-router-dom';
import data from '../../data/Data';

const Home = () => {
     const { name, title } = data;
     
     return (
          <div className='home'>
               <div>
                    <h1 className='home__title'>Hello, My name is { name }</h1>
                    <h2 className='home__title-secondary'>I am { title }</h2>
                    <p className='home__body'>Feel free to take a look at my latest articles or projects</p>
                    <div className='home__grid'>
                         {/* <Link to='blog' className='home__btn'>Blog</Link> */}
                         <Link to='cv' className='home__btn'>Resume</Link>
                         <Link to='projects' className='home__btn'>Projects</Link>
                    </div>
               </div>
          </div>
     )
};

export default Home;