import React from "react";
import { SkillBar } from "../mainComponents/index";
import { useSelector } from "react-redux";
import data from "../../data/Data";

const TextBlock = ({ children, styles }) => {
	return <div className={styles}>{children}</div>;
};

const H1 = ({ title, styles }) => {
	return <h1 className={styles}>{title}</h1>;
};

const Text = ({ text, styles }) => {
	return <p className={styles}>{text}</p>;
};

const Experience = () => {
	const skills = useSelector((state) => state.skills.data);

	const printObjectByProperty = (objArr) => {
		return objArr
			.sort((a, b) => {
				return b.progress - a.progress;
			})
			.map((item) => (
				<SkillBar
					key={item._id}
					tech={item.skill}
					number={item.progress}
					progress={item.progress}
				/>
			));
	};

	return (
		<div className="experience">
			<div className="experience__block">
				<H1 title="Summary" styles="experience__title" />
				<TextBlock styles="experience__body">
					{data["exp"].map((item, index) => (
						<Text
							key={index}
							text={item}
							styles="experience__body-text"
						/>
					))}
				</TextBlock>
			</div>
			<div className="experience__block">
				{printObjectByProperty(skills)}
			</div>
		</div>
	);
};

export default Experience;
