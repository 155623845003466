export const CREATE_SKILLS = 'CREATE_SKILLS';
export const UPDATE_SKILLS = 'UPDATE_SKILLS';
export const DELETE_SKILLS = 'DELETE_SKILLS';
export const FETCH_SKILLS = 'FETCH_SKILLS';
export const SKILL_MESSAGE = 'SKILL_MESSAGE';

export const FETCH_CV = 'FETCH_CV';
export const CREATE_CV = 'CREATE_CV';
export const UPDATE_CV = 'UPDATE_CV';
export const DELETE_CV = 'DELETE_CV';
export const CV_MESSAGE = 'CV_MESSAGE';

export const FETCH_BLOG = 'FETCH_BLOG';
export const CREATE_BLOG = 'CREATE_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const POSTS_MESSAGE = 'POSTS_MESSAGE';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const CREATE_PROJECTS = 'CREATE_PROJECTS';
export const UPDATE_PROJECTS = 'UPDATE_PROJECTS';
export const DELETE_PROJECTS = 'DELETE_PROJECTS';
export const PROJECTS_MESSAGE = 'PROJECTS_MESSAGE';

export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGN_IN';
export const SIGNOUT = 'SIGNOUT';
export const FORGOT_PWD = 'FORGOT_PWD';
export const RESET_PWD = 'RESET_PWD';
export const AUTH_MESSAGE = 'AUTH_MESSAGE';

export const PRIVATE = 'PRIVATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const PRIVATE_MESSAGE = 'PRIVATE_MESSAGE';

