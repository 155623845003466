import * as api from '../api';

import { SIGNIN, SIGNUP, SIGNOUT, FORGOT_PWD, RESET_PWD, AUTH_MESSAGE, PRIVATE, CHANGE_PASSWORD, PRIVATE_MESSAGE } from '../constants/actionTypes';

export const signinUser = (user) => async (dispatch) => {
     try {
          const { data } = await api.signinUser(user);
          dispatch({ type: SIGNIN, payload: data });
     } catch (error) {
          dispatch({ type: AUTH_MESSAGE, payload: error.response.data.error.split(',')[0]});
     }
}

export const signoutUser = (dispatch) => {
     dispatch({ type: SIGNOUT });
}

export const signupUser = (newUser) => async (dispatch) => {
     try {
          const { data } = await api.signupUser(newUser);
          dispatch({ type: SIGNUP, payload: data });
     } catch (error) {
          dispatch({ type: AUTH_MESSAGE, payload: error.response.data.error.split(',')[0]});
     }
};

export const forgotpassword = (email) => async (dispatch) => {
     try {
          const { data } = await api.forgotpassword(email);
          dispatch({ type: FORGOT_PWD, payload: data });
     } catch(error) {
          dispatch({ type: AUTH_MESSAGE, payload: error.response.data.error});
     }
};

export const resetpassword = (resetToken, password) => async (dispatch) => {
     try {
          const { data } = await api.resetpassword(resetToken, password);
          dispatch({ type: RESET_PWD, payload: data });
     } catch (error) {
          dispatch({ type: AUTH_MESSAGE, payload: error.response.data.error});
     }
};

export const getPrivateData = (token) => async (dispatch) => {

     try {
          const { data } = await api.getPrivateData(token);
          dispatch({ type: PRIVATE, payload: data });
          
     } catch (error) {
          console.log(error.message)
     }
};

export const changePassword = (id, password, token) => async (dispatch) => {
          try {
               const { data } = await api.changepassword(id, password, token);
               dispatch({ type: CHANGE_PASSWORD, payload: data });
          } catch (error) {
               dispatch({ type: PRIVATE_MESSAGE, payload: error.response.data.error});
          }
}
