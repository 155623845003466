import React, { useState, useEffect } from "react";
import { ProjectCard } from "../cards";
import { useSelector } from "react-redux";
import { Pagination } from "../mainComponents/index";
import { sortByDate } from "../../helpers/sortByDate";

const Projects = () => {
	const projects = useSelector((state) => state.projects.data);
	// Pagination variables
	const [currentPage, setCurrentPage] = useState(1);
	const [posts, setPosts] = useState([]);
	const postsPerPage = 10;
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

	useEffect(() => {
		if (projects) setPosts(sortByDate(projects));
	}, [projects]);

	return (
		<div>
			<ul>
				{currentPosts.map(({ title, _id, base64, tags }) => (
					<li key={_id}>
						<ProjectCard
							title={title}
							src={base64}
							tags={tags}
						/>
					</li>
				))}
			</ul>

			{posts.length > postsPerPage && (
				<Pagination
					totalPosts={posts.length}
					postsPerPage={postsPerPage}
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
				/>
			)}
		</div>
	);
};

export default Projects;
