export const searchByTitle = (post, title, category) => {
	const post_title = post.title.toLowerCase();
	const post_category = post.category.toLowerCase();
	const search_title = title.toLowerCase().trim();
	const search_category = category.toLowerCase().trim();

	// if there is no search word return true to all posts
	if (!search_title) {
		if (post_category.includes(search_category)) {
			return true;
		}
	}

	if (!search_category) {
		if (post_title.includes(search_title)) {
			return true;
		}
	}

	if (
		post_category.includes(search_category) &&
		post_title.includes(search_title)
	) {
		return true;
	}
};
