import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, FormButton } from '..';
import { getPrivateData, changePassword } from '../../../actions/auth';
import { PRIVATE_MESSAGE } from '../../../constants/actionTypes';


const InfoForm = () => {
     const dispatch = useDispatch();
     const data = useSelector(state => state.private);

     const [ password, setPassword ] = useState('');
     const [ confirmPassword, setConfirmPassword ] = useState('');
     const [ message, setMessage ] = useState('Save');

     const { token } = JSON.parse(localStorage.getItem('authToken'));
     
     const authToken = { 
          headers: {
               'content-type': 'application/json',
               'authorization': `Bearer ${token}`
          }
     };
     
     useEffect(() => {
          if(!data.user) dispatch(getPrivateData(authToken));
     });

     useEffect(() => {
          data.message ? setMessage(data.message) : setMessage('Save');
          if(data.message === 'Password updated'){
               setPassword('');
               setConfirmPassword('');
          }
          setTimeout(() => {
               dispatch({ type: PRIVATE_MESSAGE, payload: null });
          }, 3000);

     }, [dispatch, data.message, message]);
     
     const handleSubmit = (e) => {
          e.preventDefault();
          if (!password){
               dispatch({ type: PRIVATE_MESSAGE, payload: `Please provide password` });
          } else {
               if (password !== confirmPassword){
                    dispatch({ type: PRIVATE_MESSAGE, payload: `Password don't match confirm password` });
               } else {
                    dispatch(changePassword(data.user._id, password, authToken));
               }
          }
     };

     return (
               <form className='dashboard__form' onSubmit={ handleSubmit }>
                    <div className="dashboard__form-col">
                         <p>You sign in as: { data.user ? data.user.name : null }</p>
                    </div>
                    <div className="dashboard__form-col">
                         <p className='dashboard__form-title'>Change Password</p>
                    </div>
                    <div className="dashboard__form-col">
                         <FormInput 
                              formType='password'
                              formName='password'
                              formValue={ password }
                              formFunc={(e) => setPassword( e.target.value )}
                              formPlaceholder='Password'
                         />
                         <FormInput 
                              formType='password'
                              formName='confirmPassword'
                              formValue={ confirmPassword }
                              formFunc={(e) => setConfirmPassword( e.target.value )}
                              formPlaceholder='Confirm Password'
                         />
                    </div>
                    <div className="dashboard__form-col">
                         <FormButton 
                              text={ message }
                              message={ data.message }
                         />
                    </div>
               </form>
     );
};

export default InfoForm;