import React from 'react'

const NoMatch = () => {
     return (
          <div>
             <h1>Sorry. Somthing went wrong on our side</h1>
             <h2>Please try find page once more</h2> 
          </div>
     )
}

export default NoMatch
