import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, updatePorject, deleteProject } from '../../../actions/projects';
import { FormInput, FormFileInput, FormButton, Post, QuillTextArea, Pagination } from '../../mainComponents/index';
import { PROJECTS_MESSAGE } from '../../../constants/actionTypes';
import { sortByDate } from '../../../helpers/sortByDate';

function ProjectsForm() {
     const dispatch = useDispatch();
     const projects = useSelector(state => state.projects);

     const [ data, setData ] = useState({title: '', body: "", url: '', github: '', tags: [], base64: '', fileName: '', fileSize: ''});
     const [ message, setMessage ] = useState('Save');
     // Variable to past to quil
     const [oldProjectBody, setOldProjectBody] = useState('');

     // Pagination variables
     const [currentPage, setCurrentPage] = useState(1);
     const [ posts, setPosts ] = useState([]);
     const postsPerPage = 5;
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

     useEffect(() => {
          if(projects) setPosts(sortByDate(projects.data));
     },[projects]);

     useEffect(() => {
          projects.message ? setMessage(projects.message) : setMessage('Save');
          if(projects.message === 'Project added' || projects.message === 'Project updated'){
               clear();
               setOldProjectBody(' '); // Clears editor textarea
          }
          setTimeout(() => {
               dispatch({ type: PROJECTS_MESSAGE, payload: null })
          }, 3000)
     }, [dispatch, message, projects.message]);
     
     const handleInputs = (e) => {   
          // e.preventDefault();
          console.log(e.target.name);
          if(e.target.name === "projectImage"){
               const file = e.target.files[0];
               const reader = new FileReader();
               reader.readAsDataURL(file);
               
               reader.onload = () => {
                    setData({ ...data, fileName: file.name, fileSize: file.size, base64: reader.result });
               };
          } else if (e.target.name === 'tags'){
               setData({ ...data, tags: e.target.value.split(',') })
          } else {
               setData({ ...data, [e.target.name]: e.target.value})
          }
     };
     
     const handleSubmit = (e) => {
          e.preventDefault();
          
          if(data._id === undefined){
               dispatch(createProject(data));
          } else {
               dispatch(updatePorject(data._id, data));
          }
     };

     const clear = () => {
          setData({title: '', body: '', url: '', github: '', tags: [], base64: '', fileName: '', fileSize: ''});
     };
     
     return (
          <form autoComplete='off' noValidate className='dashboard__form' onSubmit={ handleSubmit }>
               <FormInput 
                    formType='text'
                    formName='title'
                    formValue={ data.title }
                    formFunc={ handleInputs }
                    formPlaceholder='Pick Title'
               />
               <FormInput 
                    formType='text'
                    formName='tags'
                    formValue={ data.tags }
                    formFunc={ handleInputs }
                    formPlaceholder='Technology Tags'
               />
               <FormInput 
                    formType='text'
                    formName='url'
                    formValue={ data.url }
                    formFunc={ handleInputs }
                    formPlaceholder='website link'
               />
               <FormInput 
                    formType='text'
                    formName='github'
                    formValue={ data.github }
                    formFunc={ handleInputs }
                    formPlaceholder='github link'
               />
               <FormFileInput 
                    formName='projectImage'
                    formId='projectImage'
                    formFileTitle={ data.fileName }
                    formFileSize={ data.fileSize }
                    formFunc={ handleInputs }
               />
               <QuillTextArea handleQuillBody={ setData } quillReplaceBody={ oldProjectBody }/>
               <FormButton text={ message } message={ projects.message }/>
               <div className='dashboard__form-col'>
                    { currentPosts.map(project => (
                         <Post 
                              key={project._id}
                              postTitle={project.title}
                              postDate={project.createdAt}
                              postFunc={() => {
                                   setData(project)
                                   setOldProjectBody(project.body);
                              }}
                              postBtnFunc={(e) => {
                                   e.preventDefault();
                                   dispatch(deleteProject(project._id));
                              }}
                         />
                    ))}
               </div>
               {
                    posts.length !== 0 && <Pagination 
                         totalPosts={ posts.length } 
                         postsPerPage={ postsPerPage } 
                         setCurrentPage={ setCurrentPage }
                         currentPage={ currentPage }
                    />
               }
          </form>
     )
};

export default ProjectsForm;
