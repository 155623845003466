import axios from "axios";

const API = axios.create({
	baseURL: `${
		process.env.NODE_ENV === "production"
			? "https://mzapolskyi.com/api"
			: "http://localhost:5005/api"
	}`,
});

export const getAllPosts = () => API.get("/blog");
export const addPost = (newPost) => API.post("/blog", newPost);
export const updatePost = (id, updatedPost) =>
	API.patch(`/blog/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/blog/${id}`);

export const getAllProjects = () => API.get("/projects");
export const addProject = (newProject) => API.post("/projects", newProject);
export const updateProject = (id, updatedProject) =>
	API.patch(`/projects/${id}`, updatedProject);
export const deleteProject = (id) => API.delete(`/projects/${id}`);

export const getAllSkills = () => API.get("/skills");
export const addSkill = (newSkill) => API.post("/skills", newSkill);
export const updateSkill = (id, updatedSkill) =>
	API.patch(`/skills/${id}`, updatedSkill);
export const deleteSkill = (id) => API.delete(`/skills/${id}`);

export const getAllCv = () => API.get("/cv");
export const addCv = (newCv) => API.post("/cv", newCv);
export const updateCv = (id, updateCv) => API.patch(`/cv/${id}`, updateCv);
export const deleteCv = (id) => API.delete(`/cv/${id}`);

export const getPrivateData = (token) => API.get("/private", token);
export const signinUser = (user) => API.post("/user/signin", user);
export const signupUser = (newUser) => API.post("/user/signup", newUser);
export const forgotpassword = (email) =>
	API.post("/user/forgotpassword", email);
export const resetpassword = (resetToken, password) =>
	API.put(`/user/resetpassword/${resetToken}`, { password });
export const changepassword = (id, password, token) =>
	API.patch(`/private/${id}`, { password }, token);
