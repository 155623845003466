import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PostCard } from "../cards/index";
import { Pagination } from "../mainComponents/index";
import { Search } from "../";
import { searchByTitle } from "../../helpers/searchByTitle";

const Posts = () => {
	const blog = useSelector((state) => state.blog.data);
	// Pagination variables
	const [currentPage, setCurrentPage] = useState(1);
	const [posts, setPosts] = useState([]);
	const postsPerPage = 10;
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
	const [searchValues, setSearchValues] = useState({
		title: "",
		category: "",
	});

	useEffect(() => {
		if (blog) setPosts(blog);
	}, [blog]);

	const handleSearchValues = (e) => {
		e.preventDefault();
		setSearchValues({ ...searchValues, [e.target.name]: e.target.value });
	};

	const handleSearch = (e) => {
		e.preventDefault();
		const { title, category } = searchValues;

		const filteredPosts = blog.filter((post) =>
			searchByTitle(post, title, category)
		);
		console.log(filteredPosts);
		setPosts(filteredPosts);
	};

	const handleClearSearch = (e) => {
		e.preventDefault();
		setSearchValues({ title: "", category: "" });
		setPosts(blog);
	};

	return (
		<div>
			<Search
				posts={blog}
				searchValues={searchValues}
				handleSearchValues={handleSearchValues}
				handleSearch={handleSearch}
				handleClearSearch={handleClearSearch}
			/>
			{currentPosts ? (
				<ul>
					{currentPosts
						.map(
							({
								_id,
								title,
								subtitle,
								body,
								tags,
								createdAt,
							}) => (
								<li key={_id}>
									<PostCard
										title={title}
										subtitle={subtitle}
										body={body}
										toPost={`/blog/${title.replace(
											/\s/g,
											"-"
										)}`}
										tags={tags}
										date={createdAt}
									/>
								</li>
							)
						)
						.reverse()}
				</ul>
			) : (
				<p>Loading...</p>
			)}
			{posts.length > postsPerPage && (
				<Pagination
					totalPosts={posts.length}
					postsPerPage={postsPerPage}
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
				/>
			)}
		</div>
	);
};

export default Posts;
