import React, { useEffect } from 'react';
import Header from './components/Header';
import Main from './components/Main';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { fetchAllPosts } from './actions/blog';
import { fetchAllProjects } from './actions/projects';
import { fetchSkills } from './actions/skills';
import { fetchCv } from './actions/cv';

const App = () => {

     const dispatch = useDispatch();

     useEffect(() => {
          dispatch(fetchAllPosts())
     }, [dispatch]);
     
     useEffect(() => {
          dispatch(fetchAllProjects())
     }, [dispatch]);

     useEffect(() => {
          dispatch(fetchSkills());
     }, [dispatch]);
     
     useEffect(() => {
          dispatch(fetchCv());
     }, [dispatch]);

     return (
          <BrowserRouter>
               <div className='wrapper'>
                    <Header />
                    <Main />
               </div>
          </BrowserRouter>
     )
}

export default App;