const BasicButton = ({ type, value, handleButton }) => {
	return (
		<input
			type={type}
			className="basicButton"
			name={value}
			onClick={handleButton}
			value={value}
		/>
	);
};

export default BasicButton;
