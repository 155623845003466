import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const PostCard = ({ title, subtitle, toPost, tags, date }) => {
	return (
		<React.Fragment>
			<div className="post__card">
				<div>
					<h1 className="post__card-title">{title}</h1>
					<p className="post__card-date">
						<span>published:</span>
						<span>
							<Moment fromNow>{date}</Moment>
						</span>
					</p>
				</div>
				<h2 className="post__card-subtitle">{subtitle}</h2>
				<div className="post__card-devider">
					<ul className="post__card-tags">
						{tags.map((tag) => (
							<li key={tag}>{tag}</li>
						))}
					</ul>
					<Link to={toPost} className="post__card-link">
						Read more
					</Link>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PostCard;
