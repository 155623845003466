import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signinUser, signupUser } from '../../actions/auth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AUTH_MESSAGE } from '../../constants/actionTypes';
import { AuthButton, FormInput } from '../mainComponents/index';

const Auth = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const data = useSelector(state => state.auth);
     
     const [ auth, setAuth ] = useState({ name: '', title: '', email: '', phone: '', password: '', confirmPassword: '', secretWord: '' });
     const [ isSignin, setIsSignin] = useState(true);
     const [ message, setMessage ] = useState(null);
     
     
     useEffect(() => {
          data.message ? setMessage(data.message) : setMessage(data.message);
          
          if (data.message === `Please provide valid password`) {
               setTimeout(() => {
                    dispatch({ type: AUTH_MESSAGE, payload: null });
               }, 10000);
          } else if(data.message === 'success'){
               clear();
               navigate('/dashboard');
               setTimeout(() => {
                    dispatch({ type: AUTH_MESSAGE, payload: null });
               }, 1000);
          } else {
               setTimeout(() => {
                    dispatch({ type: AUTH_MESSAGE, payload: null });
               }, 3000);
          } 

     }, [dispatch, data.message, setMessage, navigate]);
     
     const handleSubmit = (e) => {
          e.preventDefault();
          if (isSignin) {
               dispatch(signinUser(auth));
          } else {
               if (auth.password !== auth.confirmPassword) {
                    dispatch({ type: AUTH_MESSAGE, payload: `Password don't match` });
               } else {
                    dispatch(signupUser(auth));
               }
          }
     };

     const switchMode = () => {
          setIsSignin(!isSignin);
          clear();
     };
     
     const clear = () => {
          setAuth({ name: '', title: '', email: '', phone: '', password: '', confirmPassword: '', secretWord: '' });
     };

     return (
          <div className='auth'>
               <form className='auth__form' onSubmit={ handleSubmit }>
                    <div className='auth__form-col'><p className='auth__form-title'>{isSignin ? 'Sign In' : 'Sign Up'}</p></div>
                    <div className='auth__form-col'>
                         <FormInput 
                              formType='email'
                              formName='email'
                              formValue={ auth.email }
                              formFunc={(e) => setAuth({ ...auth, email: e.target.value })}
                              formPlaceholder='Email'
                         />
                         <FormInput 
                              formType='password'
                              formName='password'
                              formValue={ auth.password }
                              formFunc={(e) => setAuth({ ...auth, password: e.target.value })}
                              formPlaceholder='Password'
                         />
                         { !isSignin 
                         ?    
                              <React.Fragment>
                                   <FormInput 
                                        formType='password'
                                        formName='confirmPassword'
                                        formValue={ auth.confirmPassword }
                                        formFunc={(e) => setAuth({ ...auth, confirmPassword: e.target.value })}
                                        formPlaceholder='Confirm Password'
                                   />
                                   <FormInput 
                                        formType='text'
                                        formName='secretWord'
                                        formValue={ auth.secretWord }
                                        formFunc={(e) => setAuth({ ...auth, secretWord: e.target.value })}
                                        formPlaceholder='secretWord'
                                   />
                                   <FormInput 
                                        formType='text'
                                        formName='name'
                                        formValue={ auth.name }
                                        formFunc={(e) => setAuth({ ...auth, name: e.target.value })}
                                        formPlaceholder='Name'
                                   />
                                   <FormInput 
                                        formType='text'
                                        formName='title'
                                        formValue={ auth.title }
                                        formFunc={(e) => setAuth({ ...auth, title: e.target.value })}
                                        formPlaceholder='Title'
                                   />
                                   <FormInput 
                                        formType='text'
                                        formName='phone'
                                        formValue={ auth.phone }
                                        formFunc={(e) => setAuth({ ...auth, phone: e.target.value })}
                                        formPlaceholder='Phone Number'
                                   />
                              </React.Fragment>
                         : false }
                    </div>
                    <div className='auth__form-col'>
                         <AuthButton 
                              text={ !message ? isSignin ? 'Sign in' : 'Sign up' : message }
                              message={ message }
                         />
                    </div>
                    <div className='auth__form-col'>
                         <p onClick={ switchMode } className='auth__form-text'>{ isSignin ? `Don't have an account. Sign up` : `Already have an account? Sign in` }</p>
                         <Link to='/forgotpassword' className='auth__form-text'>Forgot Password</Link>
                    </div>
               </form>
          </div>
     )
}

export default Auth;
