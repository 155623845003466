import { PRIVATE, PRIVATE_MESSAGE, CHANGE_PASSWORD, SIGNOUT } from "../constants/actionTypes";

const privateData = ( privateData = { user: null, message: null }, action ) => {
     switch(action.type){
          case PRIVATE: 
               return action.payload;
          case SIGNOUT:
               return { ...privateData, user: null }
          case CHANGE_PASSWORD:
               return { ...privateData, message: action.payload.message };
          case PRIVATE_MESSAGE: 
               return { ...privateData, message: action.payload};
          default: 
               return privateData;
     }
};

export default privateData;