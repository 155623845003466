import { FETCH_SKILLS, CREATE_SKILLS, UPDATE_SKILLS, DELETE_SKILLS, SKILL_MESSAGE } from '../constants/actionTypes';

const SkillsReducer = (skills = { data: [], message: null }, action) => {
     switch (action.type) {
          case FETCH_SKILLS: 
               return action.payload;
          case CREATE_SKILLS: 
               return { ...skills, 
                    data: [ ...skills.data, action.payload.data],
                    message : action.payload.message 
               }
          case UPDATE_SKILLS:
               return { ...skills,
                    data : skills.data.map(skill => skill._id === action.payload.data._id 
                         ? action.payload.data 
                         : skill ),
                    message : action.payload.message
               };
          case DELETE_SKILLS:
               return { ...skills,
                    data : skills.data.filter(skill => skill._id !== action.payload.id),
                    message : action.payload.data.message
               };
          case SKILL_MESSAGE:
               return  { ...skills, message: action.payload };
          default: 
               return skills;
     };
};

export default SkillsReducer;