import React from "react";
import { FaGithub, FaPhone, FaLinkedin } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import data from "../../data/Data";

const Contact = () => {
	const { github, phone, linkedin, mail } = data;

	return (
		<div className="contact">
			<div>
				<h1 className="contact__title">Get in Touch</h1>
				<p className="contact__body">
					If you would like to get in touch send an email or call
					me
				</p>
				<div className="contact__grid">
					<a href={`mailto:${mail}`} className="contact__social">
						<GoMail />
					</a>
					<a href={`tel:${phone}`} className="contact__social">
						<FaPhone />
					</a>
				</div>
			</div>
			<div>
				<h2 className="contact__subtitle">Let's get social</h2>
				<p className="contact__body">
					Find me on GitHub or LinkedIn
				</p>
				<div className="contact__grid">
					<a
						href={github}
						className="contact__social"
						target="_blank"
						rel="noreferrer"
					>
						<FaGithub />
					</a>
					<a
						href={linkedin}
						className="contact__social"
						target="_blank"
						rel="noreferrer"
					>
						<FaLinkedin />
					</a>
				</div>
			</div>
		</div>
	);
};

export default Contact;
