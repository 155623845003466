import React from "react";
import { Link } from "react-router-dom";

function ProjectCard({ title, tags, src }) {
	return (
		<div className="project__card">
			<h1 className="project__card-title">{title}</h1>
			<img className="project__card-img" src={src} alt={title} />
			<div className="project__card-devider">
				<ul className="project__card-tags">
					{tags.map((tag) => (
						<li key={tag}>{tag}</li>
					))}
				</ul>
				<Link
					to={`/projects/${title}`}
					className="project__card-link"
				>
					Read more
				</Link>
			</div>
		</div>
	);
}

export default ProjectCard;
