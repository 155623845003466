import React, { useCallback, useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import "highlight.js/scss/atom-one-dark.scss";

import { formats } from "../../quillConfig/formats";
import { modules } from "../../quillConfig/modules";

const QuillTextArea = ({ handleQuillBody, quillReplaceBody }) => {
	const { quill, quillRef } = useQuill({ modules, formats });

	const updateOldBody = useCallback(() => {
		quillReplaceBody &&
			quill.clipboard.dangerouslyPasteHTML(quillReplaceBody);
	}, [quill, quillReplaceBody]);

	useEffect(() => {
		if (quill) {
			updateOldBody();

			quill.on("text-change", () => {
				handleQuillBody((data) => ({
					...data,
					body: quill.root.innerHTML,
				}));
			});
		}
	}, [quill, updateOldBody, handleQuillBody]);

	return (
		<div className="dashboard__form-col quillTextArea quillTextArea__focus">
			<div ref={quillRef} />
		</div>
	);
};

export default QuillTextArea;
