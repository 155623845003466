import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetpassword } from '../../actions/auth';

const ResetPwd = ({ match }) => {
     const [ pwd, setPwd ] = useState({ pwd: '', confirmPwd: '' });
     const [ error, setError ] = useState('');
     const [ success, setSuccess ] = useState('');
     const dispatch = useDispatch();
     const data = useSelector(state => state.users);
     
     useEffect(() => {
          if(data) 
          setError(data.error);
          setSuccess(data.success);
     }, [data]);

     useEffect(() => {
          if(data) 
          setSuccess(data.message);
     }, [data]);

     const handleSubmit = (e) => {
          e.preventDefault();
          if (pwd.pwd !== pwd.confirmPwd) {
               setError(`Password don't match`);
          }

          if(!error) {
               dispatch(resetpassword(match.params.resetToken, pwd.pwd ));
          }

          setTimeout(() => {
               setError('');
          }, 3000);
          clear();
     }

     const clear = () => {
          setPwd({ pwd: '', confirmPwd: '' });
     };


     return (
          <div className='auth'>
               <form className='auth__form' onSubmit={ handleSubmit }>
                    <div className='auth__form-col'><p className='auth__form-title'>Reset Password</p></div>
                    <div className='auth__form-col'> 
                         <div className='auth__form-cont' >
                              <label className='auth__form-label' htmlFor='pwd'>Password</label> 
                              <input 
                                   type="pwd" 
                                   name='pwd'
                                   value={ pwd.pwd }
                                   className='auth__form-input'  
                                   onChange={(e) => setPwd({ ...pwd, pwd: e.target.value })} 
                              />
                         </div>
                         <div className='auth__form-cont' >
                              <label className='auth__form-label' htmlFor='confirmPwd'>Repeat Password</label> 
                              <input 
                                   type="confirmPwd" 
                                   name='confirmPwd'
                                   value={ pwd.confirmPwd }
                                   className='auth__form-input'  
                                   onChange={(e) => setPwd({ ...pwd, confirmPwd: e.target.value })} 
                              />
                         </div>
                         <div className='auth__form-col'>
                              <p className='auth__form-alert'>{ error ? error : false }</p>
                              <p className='auth__form-success'>{ success ? success : false }</p>
                              <button className='auth__form-btn' type='submit'>Reset</button>
                         </div>
                    </div>
               </form>
          </div>
     )
};

export default ResetPwd;
