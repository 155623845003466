import React from 'react';

const Pagination = ({ totalPosts, postsPerPage, setCurrentPage, currentPage }) => {
     const maxPages = Math.ceil(totalPosts / postsPerPage);

     const pageNumbers = [];
     for(let i = 1; i <= maxPages; i++){
          pageNumbers.push(i);
     }

     const handlePrevClick = (e) => {
          e.preventDefault();
          setCurrentPage(currentPage - 1);
     }

     const handleNextClick = (e) => {
          e.preventDefault();
          setCurrentPage(currentPage + 1);
     }
     
     const handleOnPageClick = (number) => {
          setCurrentPage(number);
     } 

     return (
          <div className='pagination'>
               <div className='pagination__wrapper'>
                    <button 
                         className='pagination__button' 
                         onClick={ handlePrevClick } 
                         disabled={ currentPage <= 1}
                    >Prev</button>
                    <ul className='pagination__pages'>
                         {  
                              pageNumbers.slice(currentPage - 1, currentPage).map(number => (
                                   <li 
                                        key={ number } 
                                        className='pagination__page'
                                        onClick={() => handleOnPageClick(number) }
                                   >{ number }</li>
                              ))
                         }
                    </ul>
                    <button 
                         className='pagination__button'
                         onClick={ handleNextClick } 
                         disabled={ currentPage >= maxPages }
                    >Next</button>
               </div>
          </div>
    )
};

export default Pagination;