import React from 'react';
import { FiMenu } from 'react-icons/fi';

const Button = (props) => {
     return (
          <div>
               <button className='nav__button' onClick={ props.func }><FiMenu /></button>
          </div>
     )
}

export default Button
