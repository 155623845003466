import hljs from 'highlight.js';
hljs.configure({ languages: ['javascript'] });

const toolBarOptions = [
     ['bold', 'italic', 'underline', 'blockquote'],
     [{ list: 'ordered' }, { list: 'bullet' }],
     ['link', 'image'],
     ['code-block'],
     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
]

export const modules = {
     syntax: { highlight: text => hljs.highlightAuto(text).value, },
     toolbar: toolBarOptions,
     clipboard: {
          matchVisual: false,
     }
};