import React from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContentQuillCard } from "../cards";

const Post = () => {
	const { id } = useParams();
	const [post] = useSelector((state) => state.blog.data).filter(
		(post) => post.title.replace(/\s/g, "-") === id
	);

	return (
		<div className="post">
			{post ? (
				<React.Fragment>
					<h1 className="post__title">{post.title}</h1>
					<p className="post__date">
						Publish Date:{" "}
						<Moment fromNow>{post.createdAt}</Moment>
					</p>
					<ul className="post__tags">
						{post.tags.map((tag) => (
							<li key={tag}>{tag}</li>
						))}
					</ul>
					<ContentQuillCard
						quillReplaceBody={post.body}
						styles="post__body"
					/>
				</React.Fragment>
			) : (
				"Loading..."
			)}
		</div>
	);
};

export default Post;
