import React from 'react';
import { Home, Projects, Project, Experience, Cv, Contact, Posts, Post, Auth, Dashboard, ForgotPwd, ResetPwd, NoMatch } from '../pages/index';
import { Routes, Route, Navigate } from 'react-router-dom';
import {  useSelector } from 'react-redux';

const Router = () => {
     const authentication = useSelector(state => state.auth);
     const token = localStorage.getItem('authToken');


     const PrivateRoute = ({ children }) => {
          return authentication.isSigned || token !== null ? children : <Navigate to='/signin' />
     };
     
     return (
          <Routes>
               <Route exact path='/' element={ <Home /> } />
               <Route path='/projects' element={ <Projects /> } />
               <Route path={`/projects/:id`} element={ <Project /> } />
               <Route path='/blog' element={ <Posts /> } />
               <Route path={`/blog/:id`} element={ <Post /> } />
               <Route path='/cv' element={ <Cv />}/>
               <Route path='/experience' element={ <Experience /> }/>
               <Route path='/contact' element={ <Contact /> }/>
               <Route path='/signin' element={ <Auth /> } />
               <Route path='/dashboard' element={ <PrivateRoute><Dashboard /></PrivateRoute> }/>
               <Route path='/forgotpassword' element={ <ForgotPwd /> } />
               <Route path='/resetpassword/:resetToken' element={ <ResetPwd /> } />
               <Route path='*' element={ <NoMatch />} />
          </Routes>  
     )
}

export default Router;
