import { CREATE_PROJECTS, DELETE_PROJECTS, FETCH_PROJECTS, UPDATE_PROJECTS, PROJECTS_MESSAGE } from "../constants/actionTypes"; 

const projects = (projects = { data: [], message: null }, action) => {
     switch (action.type){
          case FETCH_PROJECTS: 
               return action.payload;
          case CREATE_PROJECTS:
               return { ...projects, 
                    data: [ ...projects.data, action.payload.data ],
                    message : action.payload.message
               };
          case UPDATE_PROJECTS: 
               return {...projects,
                    data : projects.data.map(project => project._id === action.payload.data._id 
                         ? action.payload.data
                         : project ),
                    message : action.payload.message
               }
               
          case DELETE_PROJECTS:
               return {
                    ...projects,
                    data : projects.data.filter(project => project._id !== action.payload.id),
                    message : action.payload.data.message
               };
          case PROJECTS_MESSAGE:
               return { ...projects, message: action.payload };
          default: 
               return projects;
     }
}

export default projects;