import React from "react";
import { useSelector } from "react-redux";

const Cv = () => {
	const cv = useSelector((state) => state.cv.data);

	return (
		<div className="cv">
			{cv.map((pdf) => (
				<iframe
					key={pdf._id}
					src={pdf.base64}
					title={pdf._id}
					type="application/pdf"
					style={{ width: "100%", minHeight: "100%" }}
				></iframe>
			))}
		</div>
	);
};

export default Cv;
