import avatar from "./../image/avatar.jpg";

const data = {
	// avatart
	name: "Mykhailo Zapolskyi",
	title: "Software Developer",
	phone: "403-990-9070",
	password: "admin password",
	img: avatar,
	// portfolio
	portfoliolink: "/projects",
	portfoliotitle: "projects",
	// cv
	cvlink: "/cv",
	cvtitle: "cv",
	// skills
	skillslink: "/experience",
	skillstitle: "experience",
	// blog
	bloglink: "/blog",
	blogtitle: "blog",
	// contact
	contactlink: "/contact",
	contacttitle: "contact",
	// login
	loginlink: "/signin",
	logintitle: "sign in",
	logouttitle: "sign out",
	//dashboard
	dashboardlink: "/dashboard",
	dashboardtitle: "dashboard",
	// social
	alt: "mikhail zapolskyi avatar image",
	mail: "mykhailo.zapolskyi@gmail.com",
	github: "https://github.com/mikhail-zapolskyi",
	linkedin: "https://www.linkedin.com/in/mikhailzapolskyi/",
	codepen: "https://codepen.io/mZapolskyi",
	exp: [
		`Software Developer with over 1 years of work experience and knowledge of React.js, 
		Node.js, JavaScript, HTML, SCSS, and other common web development technologies My eagerness 
		to continuously expand my skill set and knowledge has led me to learn new tools and features 
		which I regularly apply`,
		`With my experience in building web and/or mobile interfaces to display information, I possess 
		proven UI or UX skills that consider users with varying technical proficiency and data literacy 
		levels. Additionally, I have a thorough understanding of query languages, APIs, and data pipelines, 
		and I can work with them effectively`,
	],
};

export default data;
