import React from 'react';

function FormButton({ text, message }) {
     return (
          <div className="dashboard__form-col">
               { 
               !message 
                    ? <button type="submit" className={'dashboard__form-btn'}>{ text }</button>
                    : message.split(' ')[1] === 'updated' || message.split(' ')[1] === 'added'
                         ? <p className='dashboard__message-success'>{ text }</p>
                         : <p className='dashboard__message-danger'>{ text }</p>
               }
               {
                    message === 'Please provide valid password'   
                         ? <ul className='auth__list'>
                              <li className='auth__list-item'>at least 8 characters</li>
                              <li className='auth__list-item'>combination of upper- and lower-case characters</li>
                              <li className='auth__list-item'>at least 1 digit</li>
                              <li className='auth__list-item'>at least 1 special character</li>
                         </ul>
                         : false
               }
          </div>
     )
};

export default FormButton;
