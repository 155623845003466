import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const FormInput = ({ formType, formName, formValue, formFunc, formPlaceholder }) => {
     const [ showPwd, setShowPwd ] = useState(false);

     const showPassword = () => {
          setShowPwd(!showPwd);
     };
     
     return (
          <div className="dashboard__form-col">
               <input 
                    type={ showPwd ? 'text' : formType } 
                    name={ formName } 
                    className='dashboard__form-input' 
                    value={ formValue }
                    onChange={ formFunc }
                    placeholder={ formPlaceholder }
               />
               { formType === 'password' 
                    ? <div className='auth__form-showPwd' onClick={ showPassword }>{ showPwd ? <AiOutlineEye /> : <AiOutlineEyeInvisible /> }</div>
                    : false }
          </div>
     )
};

export default FormInput;
