import React from 'react';
import { FaRegTrashAlt } from "react-icons/fa";

const Skill = ({ skillFunc, progressFunc, skill, progress, handleDelBtn }) => {

     return (
          <React.Fragment>
               <p type="text" name="title" id="title" className='dashboard__skills-data' onClick={ skillFunc }>{ skill }</p>
               <p type="text" name="title" id="title" className='dashboard__skills-data' onClick={ progressFunc }>{ progress }</p>
               <button className='dashboard__skills-btn--delete' onClick={ handleDelBtn }><FaRegTrashAlt/></button> 
          </React.Fragment>
     )
};

export default Skill;
