import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import "highlight.js/scss/atom-one-dark.scss";
import { modules } from "../quillConfig/modules";
import { formats } from "../quillConfig/formats";
import hljs from "highlight.js";
hljs.configure({ languages: ["javascript"] });

const ContentQuillCard = ({ quillReplaceBody, styles }) => {
	const { quill, quillRef } = useQuill({ modules, formats });

	useEffect(() => {
		if (quill) {
			if (!quillReplaceBody) {
				quill.setText("");
			} else {
				quill.clipboard.dangerouslyPasteHTML(quillReplaceBody);
				quill.disable();
				quill.blur();
			}
		}

		// eslint-disable-next-line
	}, [quill, quillReplaceBody]);

	return <div className={styles} ref={quillRef} />;
};

export default ContentQuillCard;
