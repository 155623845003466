import * as api from '../api';
import { FETCH_SKILLS, CREATE_SKILLS, UPDATE_SKILLS, DELETE_SKILLS, SKILL_MESSAGE } from '../constants/actionTypes';

export const fetchSkills = () => async (dispatch) => {
     try {
          const { data } = await api.getAllSkills();
          dispatch({ type: FETCH_SKILLS, payload: data });
     } catch (error) {
          console.log(error.message);
     }
};

export const createSkill = (skill) => async (dispatch) => {
     try {
          const { data } = await api.addSkill(skill);
          dispatch({ type: CREATE_SKILLS, payload: data });
     } catch (error) {
          dispatch({ type: SKILL_MESSAGE, payload: error.response.data.error.split(',')[0] })
     }
}

export const updatedSkill = (id, skill) => async (dispatch) => {
     try {
          const { data } = await api.updateSkill(id, skill);
          dispatch({ type: UPDATE_SKILLS, payload: data })
     } catch (error) {
          dispatch({ type: SKILL_MESSAGE, payload: error.response.data.error.split(',')[0] })
     }
}

export const deleteSkill = (id) => async (dispatch) => {
     try {
          const { data } = await api.deleteSkill(id);
          dispatch({ type: DELETE_SKILLS, payload: { id, data } });
     } catch (error) {
          console.log(`message : ${ error.message }`);
     }
}