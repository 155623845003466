import React from "react";

const SkillBar = ({ progress, number, tech }) => {
	const barStyle = {
		width: `${25 + progress * 10}%`,
	};

	return (
		<div className="skillBar">
			<span className="skillBar__tech">{tech}</span>
			<span className="skillBar__progress">
				<div
					style={barStyle}
					className="skillBar__number"
				>{`${number} ${
					number > 1 ? "years" : "year"
				} of experience`}</div>
			</span>
		</div>
	);
};

export default SkillBar;
