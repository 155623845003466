import { FETCH_CV, CREATE_CV, UPDATE_CV, DELETE_CV, CV_MESSAGE } from "../constants/actionTypes";

const CvReducer = (cv = { data: [], message: null }, action) => {
     switch(action.type) {
          case FETCH_CV : 
               return action.payload;
          case CREATE_CV : 
               return { ...cv, 
                    data: [ ...cv.data, action.payload.data ], 
                    message: action.payload.message 
               };
          case UPDATE_CV : 
          return { ...cv, 
               data: cv.data.map(i => i._id === action.payload.data._id 
                    ? action.payload.data
                    : i ),
               message: action.payload.message 
          };
          case DELETE_CV : 
               return { 
                    ...cv, 
                    data: cv.data.filter(i => i._id !== action.payload.id),
                    message: action.payload.data.message
               };
          case CV_MESSAGE: 
               return { ...cv, message: action.payload };
          default : 
               return cv;
     }
}

export default CvReducer;