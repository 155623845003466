import React from 'react';
import { Link } from 'react-router-dom';

const Avatar = ({ img, alt, name, title }) => {
     return (
          <div className='avatar'>
               <Link to="/" className='avatar__link'>
                    <img src={ img } alt={ alt } className='avatar__img'/>
                    <div className='avatar__data'>
                         <h1 className='avatar__name'>{ name }</h1>
                         <h2 className='avatar__title'>{ title }</h2>
                         <p>{  }</p>
                    </div>
               </Link>
          </div>
     )
}

export default Avatar;
