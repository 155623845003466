import React from 'react';
import { FaRegTrashAlt } from "react-icons/fa";
import Moment from 'react-moment';

const Post = ({ postTitle, postDate, postFunc, postBtnFunc }) => {


     return (
          <div className='dashboard__display'>
               <div onClick={ postFunc }>
                    <p className='dashboard__display-title'>{ postTitle }</p>
                    <p className='dashboard__display-date'><strong>Published time:</strong><Moment format='"YYYY-MM-DD HH:mm"'>{ postDate }</Moment></p>
               </div>
               <button className='dashboard__display-btn' onClick={ postBtnFunc }><FaRegTrashAlt/></button>
          </div>
     )
};

export default Post;
