import { SIGNIN, SIGNUP, SIGNOUT, FORGOT_PWD, RESET_PWD, AUTH_MESSAGE } from "../constants/actionTypes";

const userAuth = ( auth = { token: null, isSigned: false, message: null }, action ) => {
     switch(action.type){
          case SIGNIN : 
               localStorage.setItem('authToken', JSON.stringify( action.payload ));
               return action.payload;
          case SIGNOUT : 
               localStorage.clear();
               return { ...auth, user: null, isSigned: false}
          case SIGNUP : 
               localStorage.setItem('authToken', JSON.stringify( action.payload ));
               return  action.payload;
          case FORGOT_PWD :
               return action.payload;
          case RESET_PWD : 
               return action.payload;
          case AUTH_MESSAGE : 
               return { ...auth, message: action.payload };
          default : 
               return auth;
     }
}

export default userAuth;