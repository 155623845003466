import { FETCH_BLOG, CREATE_BLOG, UPDATE_BLOG, DELETE_BLOG, POSTS_MESSAGE } from "../constants/actionTypes";  

const blog = (blog = { data: [], message: null }, action) => {
     switch (action.type){
          case FETCH_BLOG:
               return action.payload;
          case CREATE_BLOG :
               return { ...blog, 
                    data : [ ...blog.data, action.payload.data ], 
                    message: action.payload.message 
               };
          case UPDATE_BLOG : 
               return { ...blog, 
                    data: blog.data.map(post => post._id === action.payload.data._id 
                         ? action.payload.data
                         : post ),
                    message: action.payload.message 
               };
          case DELETE_BLOG : 
               return { 
                    ...blog, 
                    data: blog.data.filter(post => post._id !== action.payload.id),
                    message: action.payload.data.message
               };
          case POSTS_MESSAGE :
               return { ...blog, message: action.payload };
          default: 
               return blog;
     };
};

export default blog;