import React from 'react';
import Router from './Routes/Router';

const Main = () => {
     return (
          <main className='mainBlock'>
                <Router />
          </main>
     )
}

export default Main
