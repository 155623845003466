import React from 'react';

function AuthButton({ text, message }) {
     return (
          <div className="dashboard__form-col">
               {  
                    !message 
                         ? <button type="submit" className={'auth__form-btn'}>{ text }</button>
                         : message === 'success'
                              ? <p className='auth__message-success'>{ text }</p>
                              : <p className='auth__message-danger'>{ text }</p>
               }
               {
                    message === 'Please provide valid password'   
                         ? <ul className='auth__list'>
                              <li className='auth__list-item'>at least 8 characters</li>
                              <li className='auth__list-item'>combination of upper- and lower-case characters</li>
                              <li className='auth__list-item'>at least 1 digit</li>
                              <li className='auth__list-item'>at least 1 special character</li>
                         </ul>
                         : false
               }
          </div>
     )
};

export default AuthButton;