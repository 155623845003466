import React from "react";
import { FaGithub, FaCodepen, FaLinkedin } from "react-icons/fa";
import { GoMail } from "react-icons/go";

const Social = ({ mail, github, codepen, linkedin }) => {
	return (
		<div className="social">
			<a href={`mailto:${mail}`} className="social__link">
				<GoMail />
			</a>
			<a
				href={github}
				className="social__link"
				target="_blank"
				rel="noreferrer"
			>
				<FaGithub />
			</a>
			<a
				href={codepen}
				className="social__link"
				target="_blank"
				rel="noreferrer"
			>
				<FaCodepen />
			</a>
			<a
				href={linkedin}
				className="social__link"
				target="_blank"
				rel="noreferrer"
			>
				<FaLinkedin />
			</a>
		</div>
	);
};

export default Social;
