import { combineReducers } from 'redux';

import blog from './blog';
import projects from './projects';
import skills from './skills';
import CvReducer from './cv';
import userAuth from './auth';
import privateData from './private';

export default combineReducers({ 
     blog: blog,
     projects: projects,
     skills : skills,
     cv : CvReducer,
     auth : userAuth,
     private : privateData
});