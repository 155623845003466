import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avatar from "./headerComponents/Avatar";
import { NavLink, ActiveNavLink } from "./headerComponents/NavLink";
import Social from "./headerComponents/Social";
import Button from "./headerComponents/Button";
import data from "./../data/Data";
import { signoutUser } from "../actions/auth";

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [width, setWidth] = useState(window.innerWidth);
	const [toogle, setToogle] = useState("nav__mobile-notactive");

	const authentication = useSelector((state) => state.auth);
	const token = localStorage.getItem("authToken");

	window.onresize = () => {
		setWidth(window.innerWidth);
	};

	const handleButton = (e) => {
		e.preventDefault();
		toogle === "nav__mobile-notactive"
			? setToogle("nav__mobile-active")
			: setToogle("nav__mobile-notactive");
	};

	const clear = () => {
		setToogle("nav__mobile-notactive");
	};

	const handleSignOut = () => {
		dispatch(signoutUser);
		clear();
		navigate("/");
	};

	if (width >= 900) {
		return (
			<header className="header">
				<div className="nav">
					<div className="nav__col">
						<Avatar
							name={data.name}
							title={data.title}
							alt={data.alt}
							img={data.img}
						/>
					</div>
					<div className="nav__col">
						<NavLink
							link={data.portfoliolink}
							title={data.portfoliotitle}
						/>
						<NavLink
							link={data.bloglink}
							title={data.blogtitle}
						/>
					</div>
					<div className="nav__col">
						<NavLink
							link={data.cvlink}
							title={data.cvtitle}
						/>
						<NavLink
							link={data.skillslink}
							title={data.skillstitle}
						/>
						<NavLink
							link={data.contactlink}
							title={data.contacttitle}
						/>
					</div>
					<div className="nav__col">
						<ActiveNavLink
							toogleLink={
								authentication.isSigned ||
								token !== null
									? "nav__link"
									: "link__notactive"
							}
							link={data.dashboardlink}
							title={data.dashboardtitle}
						/>

						{authentication.isSigned || token !== null ? (
							<NavLink
								link="#"
								title="Sign Out"
								func={handleSignOut}
							/>
						) : (
							<NavLink
								link={data.loginlink}
								title="Sign In"
							/>
						)}
					</div>
					<div className="nav__col">
						<Social
							mail={data.mail}
							github={data.github}
							codepen={data.codepen}
							linkedin={data.linkedin}
						/>
					</div>
				</div>
			</header>
		);
	} else {
		return (
			<header className="header">
				<div className="nav">
					<div className="nav__col">
						<Avatar
							name={data.name}
							title={data.title}
							alt={data.alt}
							img={data.img}
						/>
					</div>
					<div className="nav__col nav__col-mobile">
						<Social
							mail={data.mail}
							github={data.github}
							codepen={data.codepen}
							linkedin={data.linkedin}
						/>
						<Button func={handleButton} />
					</div>
				</div>
				<div className={toogle}>
					<NavLink
						link={data.portfoliolink}
						title={data.portfoliotitle}
						func={clear}
					/>
					<NavLink
						link={data.bloglink}
						title={data.blogtitle}
						func={clear}
					/>
					<NavLink
						link={data.cvlink}
						title={data.cvtitle}
						func={clear}
					/>
					<NavLink
						link={data.skillslink}
						title={data.skillstitle}
						func={clear}
					/>
					<NavLink
						link={data.contactlink}
						title={data.contacttitle}
						func={clear}
					/>
					<ActiveNavLink
						toogleLink={
							authentication.isSigned || token !== null
								? "nav__link"
								: "link__notactive"
						}
						link={data.dashboardlink}
						title={data.dashboardtitle}
						func={clear}
					/>
					{authentication.isSigned || token !== null ? (
						<NavLink
							link="#"
							title="Sign Out"
							func={handleSignOut}
						/>
					) : (
						<NavLink
							link={data.loginlink}
							title="Sign In"
							func={clear}
						/>
					)}
				</div>
			</header>
		);
	}
};

export default Header;
