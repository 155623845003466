import * as api from "../api";
import {
	CREATE_BLOG,
	DELETE_BLOG,
	FETCH_BLOG,
	UPDATE_BLOG,
	POSTS_MESSAGE,
} from "../constants/actionTypes";

export const fetchAllPosts = () => async (dispatch) => {
	try {
		const { data } = await api.getAllPosts();
		dispatch({ type: FETCH_BLOG, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const createPost = (newPost) => async (dispatch) => {
	try {
		const { data } = await api.addPost(newPost);
		dispatch({ type: CREATE_BLOG, payload: data });
	} catch (error) {
		// [0] to show first error found from mongo
		dispatch({
			type: POSTS_MESSAGE,
			payload: error.response.data.error.split(",")[0],
		});
	}
};

export const updatePost = (id, updatedPost) => async (dispatch) => {
	try {
		const { data } = await api.updatePost(id, updatedPost);
		dispatch({ type: UPDATE_BLOG, payload: data });
	} catch (error) {
		dispatch({
			type: POSTS_MESSAGE,
			payload: error.response.data.error.split(",")[0],
		});
	}
};

export const deletePost = (id) => async (dispatch) => {
	try {
		const { data } = await api.deletePost(id);
		dispatch({ type: DELETE_BLOG, payload: { id, data } });
	} catch (error) {
		console.log(error.message);
	}
};
