import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotpassword } from '../../actions/auth';

const ForgotPwd = () => {
     const [ email, setEmail ] = useState({ email: '' });
     const [ error, setError ] = useState('');
     const [ success, setSuccess ] = useState('');
     const dispatch = useDispatch();
     const data = useSelector(state => state.users);

     useEffect(() => {
          if(data) 
          setError(data.error);
          setTimeout(() => {
               setError('');
               setSuccess('');
          }, 3000);
     }, [data]);

     useEffect(() => {
          if(data) 
          setSuccess(data.message);
          setTimeout(() => {
               setError('');
               setSuccess('');
          }, 3000);
     }, [data]);

     const handleSubmit = (e) => {
          e.preventDefault();
          if (!error) {
               dispatch(forgotpassword(email));
          }
          setTimeout(() => {
               setError('');
               setSuccess('');
          }, 3000);
          clear();
     }

     const clear = () => {
          setEmail({ email: '' });
     };

     return (
          <div className='auth'>
               <form className='auth__form' onSubmit={ handleSubmit }>
                    <div className='auth__form-col'><p className='auth__form-title'>Forgot Password</p></div>
                    <div className='auth__form-col'> 
                         <div className='auth__form-cont' >
                              <label className='auth__form-label' htmlFor='email'>Please enter the email address you register your account with. We
            will send you reset password confirmation to this email</label> 
                              <input 
                                   type="email" 
                                   name='email'
                                   value={ email.email }
                                   className='auth__form-input'  
                                   onChange={(e) => setEmail({ ...email, email: e.target.value })} 
                              />
                         </div>
                         <div className='auth__form-col'>
                              <p className='auth__form-alert'>{ error ? error : false }</p>
                              <p className='auth__form-success'>{ success ? success : false }</p>
                              <button className='auth__form-btn' type='submit'>Send</button>
                         </div>
                    </div>
               </form>
          </div>
     )
};

export default ForgotPwd;
