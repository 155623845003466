import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContentQuillCard } from "../cards";

function Project() {
	const { id } = useParams();
	const [project] = useSelector((state) => state.projects.data).filter(
		(project) => project.title === id
	);

	return (
		<div className="post">
			{project ? (
				<React.Fragment>
					<h1 className="post__title">{project.title}</h1>
					<img
						alt={project.title}
						src={project.base64}
						className="post__img"
					/>
					<ul className="post__tags">
						{project.tags.map((tag) => (
							<li key={tag}>{tag}</li>
						))}
					</ul>
					<ContentQuillCard
						quillReplaceBody={project.body}
						styles="post__body"
					/>
					<div className="post__cont">
						<a
							href={project.url}
							target="_blank"
							rel="noreferrer"
							className="post__btn"
						>
							Live Site
						</a>
						{project.github && (
							<a
								href={project.github}
								target="_blank"
								rel="noreferrer"
								className="post__btn"
							>
								Github
							</a>
						)}
					</div>
				</React.Fragment>
			) : (
				"Loading ..."
			)}
		</div>
	);
}

export default Project;
