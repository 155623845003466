import React from "react";
import { BasicButton } from "../buttons";
import { removeDuplicates } from "../../helpers/removeDuplicates";

const Search = ({
	posts,
	searchValues,
	handleSearchValues,
	handleSearch,
	handleClearSearch,
}) => {
	const { title, category } = searchValues;
	const updateCategory = removeDuplicates(
		posts.map(({ category }) => category)
	);
	return (
		<form className="search">
			<input
				type="search"
				name="title"
				className="search__input"
				placeholder="Search by Title"
				value={title && title}
				onChange={handleSearchValues}
				autoComplete="off"
			/>
			<input
				list="category"
				name="category"
				className="search__input"
				placeholder="Search by Category"
				value={category && category}
				onChange={handleSearchValues}
				autoComplete="off"
			/>
			<datalist id="category">
				<select>
					{posts &&
						updateCategory.map((category) => (
							<option value={category} key={category}>
								{category}
							</option>
						))}
				</select>
			</datalist>
			<BasicButton
				type="submit"
				value="Search"
				handleButton={handleSearch}
			/>
			<BasicButton
				type="button"
				value="Clear Search"
				handleButton={handleClearSearch}
			/>
		</form>
	);
};

export default Search;
