import React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";
import App from "./App";
import "./scss/main.scss";

const store = createStore(
	reducers,
	composeWithDevTools(compose(applyMiddleware(thunk)))
);
const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
