import React from 'react';
import { Link } from 'react-router-dom';

// navigation link used in header.js
export const NavLink = (props) => {
     return (
          <div className='navLink__container'>
               <Link to={ props.link } className='nav__link' onClick={ props.func }>{ props.title }</Link>
          </div>
     )
}

export const ActiveNavLink = (props) => {
     return (
          <div className='navLink__container'>
               <Link to={ props.link } className={ props.toogleLink } onClick={ props.func }>{ props.title }</Link>
          </div>
     )
}


