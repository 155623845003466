import React from 'react';
import { BsUpload } from "react-icons/bs";

function FormFileInput({ formName, formFunc, formId, formFileTitle, formFileSize }) {
     return (
          <label htmlFor={ formName } className='dashboard__file'>
               <BsUpload className='dashboard__file-icon'/>
               <input 
                    type="file" 
                    name={ formName }
                    id={ formId }
                    className='dashboard__file-btn'
                    onChange={ formFunc }
               />
               <p className='dashboard__file-title'>{
                    !formFileSize
                         ? 'Upload File'
                         : `${ formFileTitle} - ${ formFileSize < 1000000
                              ? `${ (formFileSize / 1000).toFixed(0) }KB` 
                              : `${ (formFileSize / 1000000).toFixed(2) }MB` }`
                         }
               </p>
          </label>
     )
};

export default FormFileInput;
