import React, { useState } from 'react';
import { InfoForm, SkillForm, CvFileForm, BlogForm, ProjectsForm, FormOption, FormSelect } from '../mainComponents/index';

const Dashboard = () => {
     const [ form, setForm ] = useState('info');
     
     const renderForm = (form) => {
          switch(form) {
               case 'info' : return <div className="dashboard__col"><InfoForm /></div>;
               case 'skills' : return <div className="dashboard__col"><SkillForm /></div>;
               case 'cv' : return <div className="dashboard__col"><CvFileForm /></div>;
               case 'blog' : return <div className="dashboard__col"><BlogForm /></div>;
               case 'projects' : return <div className="dashboard__col"><ProjectsForm/></div>;
               default : return <div className="dashboard__col"><InfoForm /></div>;
          }
     };

     return (
          <div className='dashboard'>
               <FormSelect handleSelect={(e) => { setForm(e.target.value) }} id='SelectForm' styles='dashboard__selectForm'>
                    <FormOption text='Info' value='info' styles='dashboard__selectForm-option'/>
                    <FormOption text='Skills' value='skills' styles='dashboard__selectForm-option'/>
                    <FormOption text='CV' value='cv' styles='dashboard__selectForm-option'/>
                    <FormOption text='Blog' value='blog' styles='dashboard__selectForm-option'/>
                    <FormOption text='Projects' value='projects' styles='dashboard__selectForm-option'/>
               </FormSelect>
               { renderForm(form) }
          </div>
     );
};

export default Dashboard;
