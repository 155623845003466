import * as api from '../api';
import { FETCH_PROJECTS, CREATE_PROJECTS, UPDATE_PROJECTS, DELETE_PROJECTS, PROJECTS_MESSAGE } from '../constants/actionTypes';

export const fetchAllProjects = () => async (dispatch) => {
     try {
          const { data } = await api.getAllProjects();
          dispatch({ type: FETCH_PROJECTS, payload: data });
     } catch (error){
          console.log(error.message);
     }
};

export const createProject = (newProject) => async (dispatch) => {
     try {
          const { data } = await api.addProject(newProject);
          dispatch({ type: CREATE_PROJECTS, payload: data });
     } catch (error) {
          dispatch({ type: PROJECTS_MESSAGE, payload: error.response.data.error.split(',')[0] })
     }
};

export const updatePorject = (id, updatedProject) => async (dispatch) => {
     try {
          const { data } = await api.updateProject(id, updatedProject);
          dispatch({ type: UPDATE_PROJECTS, payload: data })
     } catch (error) {
          dispatch({ type: PROJECTS_MESSAGE, payload: error.response.data.error.split(',')[0] })
     }
};

export const deleteProject = (id) => async (dispatch) => {
     try {
          const { data } = await api.deleteProject(id);
          dispatch({ type: DELETE_PROJECTS, payload: { id, data } });
     } catch (error) {
          console.log(error.message);
     }
};

