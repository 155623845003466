import * as api from '../api';
import { FETCH_CV, CREATE_CV, UPDATE_CV, DELETE_CV, CV_MESSAGE } from '../constants/actionTypes';

export const fetchCv = () => async (dispatch) => {
     try {
          const { data } = await api.getAllCv();
          dispatch({ type: FETCH_CV, payload: data })
     } catch (error) {
          console.log(error.message);
     }
};

export const createCv = (cv) => async (dispatch) => {
     try {
          const { data } = await api.addCv(cv);
          dispatch({ type: CREATE_CV, payload: data });
     } catch (error) {
          dispatch({ type: CV_MESSAGE, payload: error.response.data.error.split(',')[0] });
     }
};

export const updateCv = (id, cv) => async (dispatch) => {
     try {
          const { data } = await api.updateCv(id, cv);
          dispatch({ type: UPDATE_CV, payload: data })
     } catch (error) {
          dispatch({ type: CV_MESSAGE, payload: error.response.data.error.split(',')[0] });
     }
};
 
export const deleteCv = (id) => async (dispatch) => {
     try {
          const { data } = await api.deleteCv(id);
          dispatch({ type: DELETE_CV, payload: { id, data }})
     } catch (error) {
          console.log(error.message);
     }
}
