import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	createSkill,
	updatedSkill,
	deleteSkill,
} from "../../../actions/skills";
import { FormButton, FormInput, Skill } from "../index";
import { SKILL_MESSAGE } from "../../../constants/actionTypes";

const SkillForm = () => {
	const dispatch = useDispatch();
	const skills = useSelector((state) => state.skills);
	const [skill, setSkill] = useState({ skill: "", progress: "" });
	const [message, setMessage] = useState("Save");

	useEffect(() => {
		skills.message ? setMessage(skills.message) : setMessage("Save");
		if (
			skills.message === "Skill updated" ||
			skills.message === "Skill added"
		) {
			clear();
		}
		setTimeout(() => {
			dispatch({ type: SKILL_MESSAGE, payload: null });
		}, 3000);
	}, [dispatch, skills.message, message]);

	const handleSkillSubmit = (e) => {
		e.preventDefault();
		if (skill._id === undefined) {
			dispatch(createSkill(skill));
		} else {
			dispatch(updatedSkill(skill._id, skill));
		}
	};

	const clear = () => {
		setSkill({ skill: "", progress: "" });
	};

	return (
		<React.Fragment>
			<form
				autoComplete="off"
				noValidate
				className="dashboard__form"
				onSubmit={handleSkillSubmit}
			>
				<div className="dashboard__form-col">
					<FormInput
						formType="text"
						formName="skills"
						formValue={skill.skill}
						formFunc={(e) =>
							setSkill({ ...skill, skill: e.target.value })
						}
						formPlaceholder="Add Skill"
					/>
				</div>
				<div className="dashboard__form-col">
					<FormInput
						formType="number"
						formName="progress"
						formValue={skill.progress}
						formFunc={(e) =>
							setSkill({
								...skill,
								progress: e.target.value,
							})
						}
						formPlaceholder="Add Progress Number"
					/>
				</div>
				<FormButton text={message} message={skills.message} />
				<div className="dashboard__form-col">
					<div className="dashboard__skills">
						{skills.data.map((skill) => (
							<Skill
								key={skill._id}
								skill={skill.skill}
								progress={`${skill.progress} ${
									skill.progress > 1
										? "years"
										: "year"
								} of experience`}
								skillId={skill._id}
								skillFunc={(e) =>
									setSkill({ ...skill })
								}
								progressFunc={(e) =>
									setSkill({ ...skill })
								}
								handleDelBtn={(e) => {
									e.preventDefault();
									dispatch(deleteSkill(skill._id));
								}}
							/>
						))}
					</div>
				</div>
			</form>
		</React.Fragment>
	);
};

export default SkillForm;
