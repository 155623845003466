import React from 'react';

const FormOption = ({ text, value, styles }) => {
     return (
          <React.Fragment>
               <option value={ value } className={ styles }>{ text }</option>
          </React.Fragment>
     );
};

export default FormOption;